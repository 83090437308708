export default function GoogleLogo({ className = "size-4" }: { className?: string }) {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_1_66)">
        <path
          d="M19.9894 10.1871C19.9894 9.3677 19.9214 8.76977 19.7741 8.14969H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7345 15.0813L13.7155 15.2051L16.7429 17.4969L16.9526 17.5174C18.8789 15.7789 19.9894 13.2211 19.9894 10.1871Z"
          fill="#4285F4"
        />
        <path
          d="M10.1991 19.9314C12.9526 19.9314 15.2642 19.0455 16.9526 17.5175L13.7345 15.0814C12.8733 15.6682 11.7175 16.0779 10.1991 16.0779C7.5023 16.0779 5.2134 14.3395 4.39747 11.9367L4.27787 11.9466L1.12991 14.3273L1.08875 14.4392C2.76576 17.6946 6.21048 19.9314 10.1991 19.9314Z"
          fill="#34A853"
        />
        <path
          d="M4.3976 11.9367C4.18231 11.3167 4.05771 10.6522 4.05771 9.96574C4.05771 9.27918 4.18231 8.61483 4.38627 7.99475L4.38057 7.86269L1.19316 5.44376L1.08888 5.49223C0.397698 6.84315 0.00109863 8.36017 0.00109863 9.96574C0.00109863 11.5713 0.397698 13.0883 1.08888 14.4392L4.3976 11.9367Z"
          fill="#FBBC05"
        />
        <path
          d="M10.1992 3.85336C12.1141 3.85336 13.4059 4.66168 14.1424 5.33718L17.0206 2.59107C15.2529 0.985496 12.9526 0 10.1992 0C6.21051 0 2.76579 2.23672 1.08878 5.49214L4.38617 7.99466C5.21343 5.59183 7.50233 3.85336 10.1992 3.85336Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_66">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
